<template>
  <div class="home-container">
    <div class="sc-list">
      <div>归属城市</div>
      <div class='sc-content' style="display: flex;">
        <span>{{ city }}</span>

      </div>
    </div>
    <div class="sc-list">
      <div>选择城市</div>
      <div class='sc-content' style="display: flex;">
        <span :key="item.id" :class="idx == index ? 'clickspan' : ''" @click="selectclick(item, idx)"
          v-for="(item, idx) in citylist">{{ item.name }}</span>


      </div>
    </div>
  </div>
</template>

<script>

import {
  getUserCity
} from '@/api/user'
import {
  loadbmap
} from '@/api/loadbmap'
import {
  mapState
} from 'vuex'
import {
  getItem,
  setItem
} from '@/utils/storage'
import {
  Toast
} from 'vant';
export default {
  name: 'home',

  props: {},
  data() {
    return {
      active: 0, //被激活的列表
      channels: [], //频道列表
      isChannelEditShow: false, //频道编辑层
      navlist: [{
        name: '未使用'
      },
      {
        name: '已使用'
      },
      {
        name: '已失效'
      }

      ],
      list: [],
      city: '',
      loading: false,
      finished: false,
      refreshing: false,
      citylist: [],
      index: null
    }
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {},
  created() {
    // this.loadChannels()
  },
  mounted() {
    // Toast.loading({
    //   message: '获取当前定位中请稍等',
    //   forbidClick: false,
    //   duration:0,
    //   overlay:true
    // });
    const _this = this
    // alert('11')
    this.onLoad()
    window.initBaiduMapScript = () => {
      _this.BMap = window.BMap
      // 不需要百度定位
      return
      _this.getlocation()
    }
    loadbmap('initBaiduMapScript')


  },
  methods: {
    getlocation() {
      const _this = this
      let baidu = new _this.BMap.Geolocation()
      var city = ''
      baidu.getCurrentPosition((r) => {
        _this.latitude = r.latitude
        _this.longitude = r.longitude
        // setItem('latitude',_this.latitude)
        // setItem('longitude',_this.longitude)
        _this.city = r.address.city
        // setItem('localtion',{
        //   name:r.address.city
        // })


        _this.onLoad()
        Toast.clear()
      }, (error) => {

        Toast.clear()

      })

    },
    onLoad() {
      //  this.getcity()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    // async getcity() {
    //   // 加上归属地parentid
    //   var parentid= ''
    //   if(getItem('userArea')){
    //     parentid= getItem('userArea').parentid;
    //     this.city= getItem('userArea').name
    //   }
    //  const {
    //    data
    //  } = await getUserCity({parentid})
    //  this.citylist = data.data
    //  // console.log(data.data)
    // },
    selectclick(item, idx) {
      this.index = idx
      var that = this
      console.log(item)
      setItem('latitude', '1')
      setItem('longitude', '1')
      setTimeout(function () {
        setItem('localtion', item)
        that.index = null
        that.$router.push('/')
      }, 500)

    }
    // onUpdateActive(index) {
    //   this.active = index
    // }
  }
}
</script>

<style lang="less">
.home-container {
  .sc-list {
    font-size: 0.35rem;
    padding: 0.3rem;

    .sc-content {
      display: flex;
      flex-wrap: wrap;

      span {
        border-radius: 0.2rem;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 0.2rem;
        margin-top: 0.3rem;
        width: 23%;
        text-align: center;
        margin-right: 0.18rem;
      }

      .clickspan {
        background: rgb(230, 0, 39) !important;
        color: #fff !important;
      }

    }
  }
}
</style>
